<template>
  <section id="banner">
    <div class="content animate__animated animate__fadeIn">
      <h2>BEM (SIB) OLIVE GARDEN</h2>
      <p class="text-white">
        Welcome Guests to our church! Please leave us a feedback on our Sunday
        Service by clicking the button below.
      </p>

      <div class="flex flex-wrap justify-center gap-6">
        <div>
          <a
            href="javascript:void(0)"
            @click="$router.push({ name: 'Sunday-Service' })"
            class="button scrolly hover:bg-gray-500"
            >Sunday Service</a
          >
        </div>
        <div>
          <a
            href="javascript:void(0)"
            @click="$router.push({ name: 'Visitor' })"
            class="button scrolly"
            >Visiting Guest</a
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Home",
  created() {},
};
</script>

<style scoped>
</style>
